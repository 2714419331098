import React, { useEffect, useCallback, useState } from "react"
import { graphql, navigate } from "gatsby"
import axios from "axios"
import clsx from "clsx"
import { SiteLayout, Head, Button, Field, Form } from "~/components"
import * as contact from "~/pages/contact/contact.module.scss"
import { IconArrowRight } from "~/icons"

const ContactPage = ({ location, data }) => {
  const siteTitle = data.site.siteMetadata.title
  const [isLoading, setIsLoading] = useState(false)

  const handleSent = useCallback(() => {
    setIsLoading(true)
    const {
      subject,
      company_name,
      department,
      sender_name,
      sender_kana,
      tel,
      email,
      comment,
    } = location.state?.params

    const data = `{"blocks": [{"type":"header", "text": { "type": "plain_text", "text":"お問い合わせ","emoji": true} }, { "type":"section", "text": { "type":"mrkdwn", "text":"*区分：*\n ${subject}"} }, { "type":"section", "text": { "type":"mrkdwn", "text":"*会社名：*\n ${company_name}"} }, { "type":"section", "text": { "type":"mrkdwn", "text":"*部署：*\n ${department}"} }, { "type":"section", "text": { "type":"mrkdwn", "text":"*氏名：*\n ${sender_name}"} }, { "type":"section", "text": { "type":"mrkdwn", "text":"*ふりがな：*\n ${sender_kana}"} }, { "type":"section", "text": { "type":"mrkdwn", "text":"*電話番号：*\n ${tel}"} }, { "type":"section", "text": { "type":"mrkdwn", "text":"*メールアドレス：*\n ${email}"} }, { "type":"section", "text": { "type":"mrkdwn", "text":"*お問い合わせ：*\n ${comment}"}}]}`

    axios({
      method: "post",
      url:
        "https://hooks.slack.com/services/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",
      data,
    })
      .then(response => {
        sessionStorage.removeItem("aizulab_contact_form")
        navigate("/contact/thanks/", {
          state: {
            process: "thanks",
          },
        })
      })
      .catch(error => {
        setIsLoading(false)
        console.log("error")
      })
  }, [location.state])

  const goBack = useCallback(() => {
    navigate("/contact/", {
      state: {
        params: location.state?.params,
        process: "index",
      },
    })
  }, [location.state])

  useEffect(() => {
    if (location.state?.process !== "preview") navigate("/contact/")
  })

  return (
    <SiteLayout title={siteTitle}>
      <Head title="お問い合わせ (入力確認)" />

      <div className={contact.content}>
        <div className={contact.hero}>
          <h1 className={contact.title}>お問い合わせ</h1>
          <p className={contact.message}>
            休業日・祝日の前後はご回答が遅れる場合がございますのであらかじめご了承ください。
            <br />
            フォームからお問い合わせ頂きましたら折り返しご連絡いたします。
          </p>
          <div className={contact.flow}>
            <div className={contact.dot} />
            <div className={contact.activeDot} />
            <div className={contact.dot} />
          </div>
        </div>

        <Form noValidate>
          <div className={contact.inner}>
            <Field className={contact.field} tag="任意" label="区分">
              <div className={contact.fieldText}>
                {location.state?.params.subject}
              </div>
            </Field>
            <Field className={contact.field} tag="必須" label="会社名">
              <div className={contact.fieldText}>
                {location.state?.params.company_name}
              </div>
            </Field>
            <Field className={contact.field} tag="任意" label="部署">
              <div className={contact.fieldText}>
                {location.state?.params.department}
              </div>
            </Field>
            <Field className={contact.field} tag="必須" label="氏名">
              <div className={contact.fieldText}>
                {location.state?.params.sender_name}
              </div>
            </Field>
            <Field className={contact.field} tag="任意" label="ふりがな">
              <div className={contact.fieldText}>
                {location.state?.params.sender_kana}
              </div>
            </Field>
            <Field className={contact.field} tag="必須" label="電話番号">
              <div className={contact.fieldText}>
                {location.state?.params.tel}
              </div>
            </Field>
            <Field className={contact.field} tag="必須" label="メールアドレス">
              <div className={contact.fieldText}>
                {location.state?.params.email}
              </div>
            </Field>
            <Field
              className={contact.field}
              tag="必須"
              label="お問い合わせ内容"
            >
              <pre className={contact.fieldPre}>
                {location.state?.params.comment}
              </pre>
            </Field>
          </div>
          <div className={contact.controllbar}>
            <Button
              className={clsx(contact.button, contact.backButton)}
              variant="Confirm"
              onClick={goBack}
            >
              入力ページにもどる
            </Button>
            <Button
              className={contact.button}
              variant="Confirm"
              onClick={handleSent}
              disabled={isLoading}
            >
              送信する
            </Button>
          </div>
        </Form>

        <dl className={contact.def}>
          <dt className={contact.defTitle}>
            ■
            お急ぎの場合はお電話にて問い合わせください。担当者が不在の場合は折り返しのご連絡となります。
          </dt>
          <dd className={contact.defText}>
            Tel{" "}
            <a className={contact.tel} href="tel:0242-23-8285">
              0242-23-8285
            </a>{" "}
            (平日 9:30~17:30)
          </dd>
        </dl>

        <dl className={contact.def}>
          <dt className={contact.defTitle}>
            ■ 採用についてはこちらをご確認ください。
          </dt>
          <dd className={contact.defText}>
            <a
              href="https://www.green-japan.com/company/7196"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-slim"
            >
              <span>採用情報はこちら</span>
              <IconArrowRight />
            </a>
          </dd>
        </dl>
      </div>
    </SiteLayout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
